import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SalesforceChatService {

  constructor() { }

  startSalesforceChat() {
    setTimeout(() => {
      const sfScript1 = document.createElement('script');
      document.getElementById('sfTag').appendChild(sfScript1);
      sfScript1.type = 'text/javascript';
      sfScript1.src = 'assets/js/salesforceChat.js';
  
      var helpButton = document.getElementsByClassName('helpButton');
      if( helpButton[0] !== undefined){
        helpButton[0].setAttribute("style","display:block;");
      }
      var sidebar = document.getElementsByClassName('embeddedServiceSidebar');
      if(sidebar[0] !== undefined) {
        sidebar[0].setAttribute("style","display:block;");
      }
      var embeddedServiceHelpButton = document.getElementsByClassName('embeddedServiceHelpButton');
      if(embeddedServiceHelpButton[0] !== undefined) {
        embeddedServiceHelpButton[0].setAttribute("style","display:block;");
      }
    }, 1000);
  }

  removeSalesforceChat() {
    var helpButton = document.getElementsByClassName('helpButton');
    if(helpButton[0] !== undefined) {
      helpButton[0].setAttribute("style","display:none;");
    }
    var sidebar = document.getElementsByClassName('embeddedServiceSidebar');
    if(sidebar[0] !== undefined) {
      sidebar[0].setAttribute("style","display:none;");
    }
    var embeddedServiceHelpButton = document.getElementsByClassName('embeddedServiceHelpButton');
    if(embeddedServiceHelpButton[0] !== undefined) {
      embeddedServiceHelpButton[0].setAttribute("style","display:none;");
    }
  }
}
